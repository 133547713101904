* {
  font-family: Poppins;
}

#root {
  width: 100vw;
}

div {
  border: 1px dotted blue;
  box-sizing: border-box;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

h2 {
  margin-top: 10px;
  margin-bottom: 0;
}

p {
  margin: 0;
}

/* .container {
  width: 100%;
}
.project-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.project-image {
  flex: 30%;
  max-height: 80vh;
  margin-right: 10px;
}

.project-title {
  margin-top: 3vw;
  flex: 66%;
} */

img {
  margin: 5% 0 5% 0;
  max-height: 100%;
  max-width: 100%;
}

.body {
  margin-top: 64px;
  margin-left: 20vw;
  margin-right: 10vw;
}

@media (max-width: 800px) {
  .body {
    margin-top: 64px;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}
