.headerContainer {
  align-content: flex-start;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  position: fixed;
  padding: 16px;
  z-index: 2;
}

.headerNav ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  gap: 10px;
}

a:hover {
  color: #61dafb;
}
.menuIcon {
  width: 32px;
  height: 32px;
}
.menuIcon:hover {
  opacity: 70%;
}
.closeIcon {
  width: 32px;
  height: 32px;
  color: white;
}
