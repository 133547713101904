.invisibleContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0%;
  background-color: transparent;
}
.visibleContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: background-color ease 1s;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.menuIcon {
  width: 32px;
  height: 32px;
  color: white;
}
